<template>
    <div>
        <ul class="my_list">
            <li v-for="item in item_" :key="item.id">
                <div class="user_top">
                    <div class="user_fl" @click="$router.push('/user/'+item.user.id)">
                        <!-- <div class="avatar" :style="{'background-image':'url('+user.headimgurl+')'}"></div> -->
                        <div class="avatar" :style="{'background-image':'url('+item.user.headimgurl+')'}"></div>
                        <div class="user_name">
                            <div>{{item.user.title}}</div>
                            <p>{{item.create_time}}</p>
                        </div>
                    </div>
                    <div class="user_fr">
                        <div class="replyBtn" v-if="commentType == 5">回复</div>
                        <template v-else>
                            <FollowBtn v-if="item.user.isCanFollow" :user_id="item.user.id" :is_follow="item.user.isFollow" />
                        </template>
                    </div>
                </div>
                <div class="my_reply" v-if="commentType == 1" v-html="item.my_comment.msg"></div>
                <div class="my_reply" v-if="commentType == 2"><img src="~@/assets/img/zan.png">赞了我</div>
                <div class="my_reply collect" v-if="commentType == 3"><img src="~@/assets/img/collect.png">已收藏</div>

                <div class="my_reply" v-if="commentType == 4"><img src="~@/assets/img/zan.png">我赞了</div>
                <div class="send_con" @click="$router.push('/post/'+item.id)">
                    <div class="pic">
                        <img  v-if="item.post_type==1 && item.imgs.length>0" :src="item.imgs[0].thumb">
                        <video v-if="item.post_type==2" controls :src="item.video_src"></video>
                        <audio v-if="item.post_type==3" controls :src="item.audio_src"></audio>
                    </div>
                    <div class="txt">
                        <div v-if="item.title">{{item.title}}</div>
                        <p v-html="item.body" />
                        <div class="post-body-link"  v-if="item.post_type==4">
                        <a :href="item.link.src"><img src="~@/assets/img/link.png">{{item.link.title?item.link.title:'查看详情'}}</a>
                        </div>
                    </div>
                </div>
                <!-- <template v-if="commentType==1">
                <div class="comment">荣华悦：<span>少，却更好</span></div>
                </template> -->
            </li>
        </ul>
    </div>
</template>
<script>

import FollowBtn from '@/components/FollowBtn.vue'
export default {
    name:'CommentList',
    data(){
        return{
            item_: this.item,
        }
    },
    
  components: {
    FollowBtn
  },
    props:{
        item: Object,
        commentType: Number  // 1:我的评论  2：点赞我的  3:收藏 4:我的点赞 5：评论我的
    }
}
</script>
<style lang="less" scoped>
.my_list{
    li{
        background: #fff;
        margin-bottom: .32rem;
        padding: .32rem ;
        .user_top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .user_fl{
                display: flex;
                align-items: center;
                .avatar{
                    width: .8rem;
                    height: .8rem;
                    border-radius: 50%;
                    background-size: cover !important;;
                    margin-right: .16rem;
                    background: #888;
                }
                .user_name{
                    div{
                        font-size: .28rem;
                        color: #333;
                        font-weight: bold;
                    }
                    p{
                        font-size: .24rem;
                        color: #999;
                    }
                }
            }
            .user_fr{
                .replyBtn{
                    width: 1.2rem;
                    height: .6rem;
                    line-height: .6rem;
                    border-radius: .3rem;
                    border: .02rem solid #F65609;
                    font-size: .28rem;
                    color: #F65102;
                    text-align: center;
                }
                .follow{
                    height: .42rem;
                    line-height: .42rem;
                    background: #F65507;
                    border-radius: .24rem;
                    padding: 0 .16rem;
                    font-size: .24rem;
                    color: #fff;
                    &.on{
                        background: rgba(0, 0, 0, .1);
                    }
                }
            }
        }
        .my_reply{
            font-size: .28rem;
            color: #333;
            margin: .24rem 0 .08rem;
            img{
                width: .28rem;
                height: .28rem;
                margin-right: .16rem;
            }
            &.collect img{
                width: .24rem;
            }
        }
        .send_con{
            display: flex;
            justify-content: space-between;
            width: 100%;
            background: #f8f8f8;
            height: 1.92rem;
            .pic{
                width: 1.92rem;
                height: 1.92rem;
                overflow: hidden;
                /*background: red;*/
                img{
                    width: 100%;
                    display: block;
                    object-fit: cover;
                }
                video{width: 100%;height: 100%;}
                audio{width: 100%;height: 100%;}
            }
            .txt{
                width: 4.4rem;
                padding: .22rem;
                overflow: hidden;
                div{
                    font-size: .26rem;
                    color: #333;
                    font-weight: bold;
                    margin-bottom: .1rem;
                }
                p{
                    font-size: .24rem;
                    color: #333;
                }
            }
        }
        .comment{
            font-size: .26rem;
            color: #333;
            margin-top: .16rem;
            span{
                color: #F65102;
            }
        }
    }
    
//.post-body-link{margin-top:0.3rem;}
.post-body-link a{font-size: 0.3rem;color:#EC3A40;}
.post-body-link img{height: 0.3rem;}
}
</style>