<template>
  <div class="topic">
      <BackBar :pageTit="headTit" />
    <vue-scroll class="main-page" :ops="ops"
      @refresh-start="handleRefresh"
      @load-start="handleLoad"
       ref="vs"
    >
      <!-- <PostItem v-for="item in posts"  :isDescPage="false" :key="'posts'+item.id" :item="item" /> -->
      <CommentList :item="posts" :commentType="commentType" />
    </vue-scroll>
   
  </div>
</template>

<script>
import BackBar from '@/components/BackBar.vue'
import Api from '@/api'
import Vue from 'vue'
import CommentList from '@/components/CommentList.vue'

export default {
  name: 'MyLike',
  data(){
    const ops = {
      vuescroll: {
        mode: 'slide',
        pullRefresh: {
          enable: true
        },
        pushLoad: {
          enable: true,
        }
      }
    };
   
      ops.vuescroll.pullRefresh.tips = {
        deactive: '下拉刷新',
        active: '释放刷新',
        start: '刷新中...',
        beforeDeactive: '刷新成功!'
      };
      ops.vuescroll.pushLoad.tips = {
        deactive: '上拉加载',
        active: '释放加载',
        start: '加载中...',
        beforeDeactive: '加载成功!'
      };
    

    return {
      posts:[],
      page:1,
       ops:ops,
       isloading:false,
       topic:{},
       headTit:'点赞我的',
       commentType:2
    }
  },
  components: {
    BackBar,
    CommentList
  },
  methods:{
    handleRefresh(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('refresh')
      this.page=1;
      this.posts=[];
      this.refreshPost(done)
    },
    handleLoad(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('handleLoad')
      this.refreshPost(done)
    },
    refreshPost(cb){
      var that=this
      this.isloading=true
      
      Api.go('like_to_me',{page:this.page++},(res)=>{
        var _=that.posts
        if(res.length>0){
          for(var i in res){
            _.push(res[i])
          }
        }else{
          Vue.prototype.$toast('暂无更多')
        }
        if(cb){cb();}
        that.isloading=false;
        
        setTimeout(() => {
          that.$refs.vs.refresh();
        }, 100)
      }) 
    }
  },
  mounted(){
      
    this.refreshPost()
  }
}
</script>

<style scoped>
.topic{padding-bottom: 1rem;position: fixed;left: 0;top:0;width: 100%;height: 100%;    box-sizing: border-box;}
.topic h2{line-height: 1rem;text-align: center;border-bottom: 1px solid #ccc;}
.newpost-btn{position: fixed;display: block;
bottom: 1.4rem;right: 0.2rem;height: 1.14rem;width:1.14rem;
background: url(~@/assets/img/home-add.png) no-repeat center center/cover;}

</style>